import React from "react";
import { Helmet } from "react-helmet";

import Courses from "./components/courses";
import Testimonials from "./components/testmonials";

import Events from "./components/event";
import News from "./components/news";
import LifeClub from "./components/lifeClub";
import MidBanner from "./components/midBanner";
import AboutUs from "./components/aboutUs";
import Homebanner from "./components/homeBanner";

export const Home = () => {
  return (
    <>
      <Helmet>
        <body className="home-video-active" />
      </Helmet>
      <h1 hidden>SEO</h1>
      <Homebanner />
      <Courses />
      <AboutUs />
      <MidBanner />
      <LifeClub />
      <News />
      <Events />
      <Testimonials />
    </>
  );
};
